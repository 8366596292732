#hidden-scrollbar::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}


/* Better scrollbars */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4); 
}