@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/switch.css";
@import "./styles/steps.css";
@import "./styles/scrollbar.css";
@import "./styles/rsuite-modal.css";

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: "Montserrat", sans-serif !important;
  background-color: #f9f9f9;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* max-width: 1440px; */
  /* margin: 0 auto; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}




.rs-btn-primary, .rs-btn-primary:active, .rs-btn-primary:focus {
  background-color: #4A6CD1;
}

.rs-btn-primary:hover {
  background-color: #4F6CFF;
}

.rs-modal-header .rs-btn-close[title="Close"] {
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.rs-modal-header > .rs-btn-close[title="Close"]:hover {
  background-color: #f0f0f0;
}

.react-multiple-carousel__arrow{
  background-color: black;
}

#quill-editor .ql-toolbar, #quill-editor .ql-container {
  border-color: #4A6CD1;
}

#quill-editor > .ql-toolbar {
  border-radius: 5px 5px 0 0;
  background-color: #4A6CD1;
  border-bottom: 0;
}

#quill-editor > .ql-container {
  border-radius: 0 0 5px 5px;
  background-color: #f9f9f9;
  border-top: 0;
  height: 12rem;
}

#quill-editor > .ql-toolbar > .ql-formats {
  color: white;
  display: flex;
  gap: 2px;
  width: fit-content;
}

#quill-editor > .ql-toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  width: 100%;
}

#quill-editor .ql-stroke, #quill-editor .ql-picker-label, #quill-editor .ql-underline, #quill-editor .ql-list {
  color: white;
  stroke: white;
}

#quill-editor .ql-active {
  background-color: #27277F;
  border-radius: 4px;
}

.rs-picker-daterange-menu, .rs-picker-menu {
  z-index: 9999999;
}

.rs-dropdown-item-with-icon {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 6px;
  position: fixed;
}

/* ::selection {
  color: white;
  background-color: black;
} */

html {
  @apply relative m-0 w-full max-w-[100vw] touch-pan-y overflow-x-clip scroll-smooth bg-no-repeat p-0 leading-[inherit] [box-sizing:inherit];
}

body, #root, .rs-container {
  @apply relative m-0 w-full max-w-[100vw] touch-pan-y !overflow-x-clip bg-no-repeat p-0 leading-[inherit] [box-sizing:inherit];
}

@layer demo, countdown;

@layer countdown{
  .countdown {
    width: fit-content;
    display: inline-grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 1rem;
    margin: 0 auto;
    user-select: none;
  }
  .countdown > .part {
    display: grid;
    gap: 0.5rem;
  }
  .countdown > .part > .number {
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 1px solid hsl(0 0% 50% / .5);
    font-size: 1.5rem;
    position: relative
  }
  .countdown > .part.days { --num: 365 }
  .countdown > .part.hours { --num: 24 }
  .countdown > .part:where(.minutes, .seconds) { --num: 60 }
  .countdown > .part > .number::after{
    content: "";
    position: absolute;
    inset: calc(var(--border-size) * -0.5);
    border-radius: inherit;

    --degree: calc(360deg / var(--num) * (var(--num) - var(--value,0)));
    background-image: conic-gradient(var(--accent) var(--degree), transparent calc(var(--degree) + 0.1deg));

    --border-size: 4px;  
    --mask-image: radial-gradient(100% 100%, transparent calc(50% - var(--border-size)), black calc(50% - var(--border-size) + 1px));
    -webkit-mask-image: var(--mask-image);
    mask-image: var(--mask-image);
  }
  .countdown > .part > .text {
    text-align: center;
    opacity: 0.75
  }
}