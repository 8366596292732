#rs-modal-content .rs-modal-content {
  padding: 0;
  transition: all 0.2s ease-in-out;
}

/* .rs-modal-full .rs-modal-content {
  border-radius: 0;
  height: 100vh;
} */

/* .rs-modal-full.rs-modal {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
} */

/* RSUITE MODAL HEADER */
.rs-modal-header#rs-modal-header {
  padding: 1rem;
  background-color: #dddddd;
  border-radius: 6px 6px 0 0;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 400px -82px rgba(0,0,0,0.14) inset;
  -webkit-box-shadow: 0px 0px 400px -82px rgba(0,0,0,0.14) inset;
  -moz-box-shadow: 0px 0px 400px -82px rgba(0,0,0,0.14) inset;
}
#rs-modal-header .rs-modal-header-close {
  /* color: white; */
  padding: 8px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  top: 14px;
}

#rs-modal-header .rs-modal-header-close:hover {
  background-color: #dddddd;
  color: #333333;
  /* color: white; */
}

/* RSUITE MODAL BODY */
.rs-modal-body#rs-modal-body {
  padding: 1rem;
  margin: 0;
  background-color: #f9f9f9;
}

/* RSUITE MODAL FOOTER */

.rs-modal-footer#rs-modal-footer {
  padding: 0.5rem 1.51rem;
  background-color: #eeeeee;
  border-radius: 0 0 6px 6px;
  transition: all 0.2s ease-in-out;
}