#toggle-switch {
  width: 50px;
  height: 25px;
  border-radius: 25px;
  border: none;
  background-color: #ddd;
  position: relative;
  transition: background-color 0.2s;
}

#toggle-switch::before {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #fff;
  transition: transform 0.2s;
}

#toggle-switch.active {
  background-color: #3498ff;
}

#toggle-switch.active::before {
  transform: translateX(25px);
}

#home-switch {
  width: 50px;
  height: 25px;
  border-radius: 25px;
  border: none;
  background-color: #3498ff;
  position: relative;
  transition: background-color 0.2s;
}

#home-switch::before {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #fff;
  transition: transform 0.2s;
}

#home-switch.active {
  background-color: #F47629;
}

#home-switch.active::before {
  transform: translateX(25px);
}


@media (max-width: 768px) {
  #toggle-switch {
    width: 30px;
    height: 15px;
  }

  #toggle-switch::before {
    width: 10px;
    height: 10px;
  }

  #home-switch {
    width: 30px;
    height: 15px;
  }

  #home-switch::before {
    width: 10px;
    height: 10px;
  }

  #toggle-switch.active::before {
    transform: translateX(15px);
  }

  #home-switch.active::before {
    transform: translateX(15px);
  }
}